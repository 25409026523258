/**
 * This is the entry point for the admin pages.
 */
export const domainAwareAdminPath = (path: string) => {
  // if the domain begins with "admin" we can strip the /admin prefix from the path
  const domain = window.location.hostname;
  const domainStartsWithAdmin = domain.startsWith("admin");
  const finalPath = domainStartsWithAdmin ? path.replace("/admin", "") : path;
  return finalPath;
};

const getQueryParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams;
};

const setCheckboxCheckedState = (checkboxId: string, paramName: string) => {
  const checkbox = document.getElementById(checkboxId);
  if (!checkbox || !(checkbox instanceof HTMLInputElement)) {
    return;
  }
  const queryParams = getQueryParams();
  if (queryParams.has(paramName)) {
    checkbox.checked = true;
  } else {
    checkbox.checked = false;
  }
};

const init = () => {
  window.onload = function () {
    // header profile button -> drop down menu, see AdminHeader.eta
    const profileButton = document.getElementById("header-profile-picture");
    if (profileButton) {
      profileButton.addEventListener("click", () => {
        const headerDropdown = document.getElementById("header-dropdown");
        headerDropdown?.classList.toggle("hide");
      });
    }

    // Tiniest bit of JS to make the ITIN Application List checkboxes work
    setCheckboxCheckedState("unmatched", "Unmatched");
    setCheckboxCheckedState("mailing-address-set", "MailingAddress");
  };
};

init();
